
import {
  Component,
  Prop,
  Vue,
} from 'nuxt-property-decorator'
import { ArticleEntity } from '~/graphql/generated'

@Component
export default class ArticleList extends Vue {
  @Prop() data!: ArticleEntity[]
}
