import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=c0a26518&"
import script from "./ArticleList.vue?vue&type=script&lang=ts&"
export * from "./ArticleList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/vercel/path0/components/Icon.vue').default,HeadlineLabel: require('/vercel/path0/components/HeadlineLabel.vue').default,SharedButton: require('/vercel/path0/components/shared/Button.vue').default})
